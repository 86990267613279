@font-face {
  font-family: 'quix';
  src:
    url("../fonts/quix.ttf?9zs797") format("truetype"),
    url("../fonts/quix.woff?9zs797") format("woff"),
    url("../fonts/quix.svg?9zs797#quix") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'quix' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-add:before {
  content: "\e900";
}
.ico-approved:before {
  content: "\e901";
}
.ico-area:before {
  content: "\e902";
}
.ico-brand:before {
  content: "\e903";
}
.ico-brands-solid:before {
  content: "\e904";
}
.ico-calendar:before {
  content: "\e905";
}
.ico-channel:before {
  content: "\e906";
}
.ico-chevron-down:before {
  content: "\e907";
}
.ico-chevron-left:before {
  content: "\e908";
}
.ico-chevron-right:before {
  content: "\e909";
}
.ico-chevron-up:before {
  content: "\e90a";
}
.ico-cross:before {
  content: "\e90b";
}
.ico-customers:before {
  content: "\e90c";
}
.ico-cuts:before {
  content: "\e90d";
}
.ico-date:before {
  content: "\e90f";
}
.ico-delete:before {
  content: "\e910";
}
.ico-designs:before {
  content: "\e911";
}
.ico-designs-solid:before {
  content: "\e920";
}
.ico-devices:before {
  content: "\e912";
}
.ico-disapproved:before {
  content: "\e913";
}
.ico-distributor:before {
  content: "\e914";
}
.ico-download:before {
  content: "\e915";
}
.ico-edit:before {
  content: "\e916";
}
.ico-excel:before {
  content: "\e917";
}
.ico-export:before {
  content: "\e918";
}
.ico-globe:before {
  content: "\e919";
}
.ico-image:before {
  content: "\e91a";
}
.ico-import:before {
  content: "\e91b";
}
.ico-importer:before {
  content: "\e91c";
}
.ico-languages:before {
  content: "\e91d";
}
.ico-library:before {
  content: "\e91e";
}
.ico-locations:before {
  content: "\e91f";
}
.ico-logout:before {
  content: "\e921";
}
.ico-pdf:before {
  content: "\e922";
}
.ico-plotter:before {
  content: "\e923";
}
.ico-profile:before {
  content: "\e932";
}
.ico-promoters:before {
  content: "\e924";
}
.ico-publish:before {
  content: "\e925";
}
.ico-q1:before {
  content: "\e926";
}
.ico-q2:before {
  content: "\e927";
}
.ico-q3:before {
  content: "\e928";
}
.ico-q4:before {
  content: "\e929";
}
.ico-qr:before {
  content: "\e92a";
}
.ico-refresh:before {
  content: "\e92b";
}
.ico-reports:before {
  content: "\e92c";
}
.ico-retail-chain:before {
  content: "\e92d";
}
.ico-retail-store:before {
  content: "\e92e";
}
.ico-save:before {
  content: "\e92f";
}
.ico-search:before {
  content: "\e930";
}
.ico-settings:before {
  content: "\e931";
}
.ico-share:before {
  content: "\e941";
}
.ico-sort-down:before {
  content: "\e933";
}
.ico-sort-up:before {
  content: "\e934";
}
.ico-sync:before {
  content: "\e935";
}
.ico-upload:before {
  content: "\e936";
}
.ico-user-authentication:before {
  content: "\e937";
}
.ico-users:before {
  content: "\e938";
}
.ico-view:before {
  content: "\e939";
}
.ico-warranty:before {
  content: "\e93a";
}
.ico-zip:before {
  content: "\e93b";
}
.ico-sort-default:before {
  content: "\e93c";
}
.ico-promoters-solid:before {
  content: "\e93d";
}
.ico-apple:before {
  content: "\e93e";
}
.ico-android:before {
  content: "\e93f";
}
.ico-graph:before {
  content: "\e940";
}
.ico-plotter-solid:before {
  content: "\e90e";
}
.ico-zoom-in:before {
  content: "\e942";
}
.ico-zoom-out:before {
  content: "\e943";
}
.ico-eye-closed:before {
  content: "\e944";
}
.ico-warranty-solid:before {
  content: "\e945";
}
.ico-success:before {
  content: "\e946";
}
.ico-fail:before {
  content: "\e947";
}
.ico-firmware:before {
  content: "\e948";
}
.ico-bars:before {
  content: "\e949";
}
.ico-user:before {
  content: "\e94a";
}
.ico-filter:before {
  content: "\e94b";
}
.ico-info .path1:before {
  content: "\e94c";
  color: rgb(0, 0, 0);
}
.ico-info .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-info .path3:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-exclaimation .path1:before {
  content: "\e94f";
  color: rgb(0, 0, 0);
}
.ico-exclaimation .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-exclaimation .path3:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-log:before {
  content: "\e952";
}
